import { openDB } from 'idb'
import Mapper from '@/classes/Core/Database/Mapper'

export default class Database
{
    
    constructor( core )
    {
        
        if ( !Database.instance ) {
            
            this.logger = core.getLogger()
            this.uuid = core.getUuid()
            this.eventManager = core.getEventManager()
            
            this.setState = ( key, value ) =>
            {
                return core.setState( key, value )
            }
            
            this.getState = ( key ) =>
            {
                return core.getState( key )
            }
            
            this.databaseName = 'entzettelt'
            this.databaseVersion = 30
            this.mapper = false
            this.resetState = false
            
            this.logger.cconstructed( 'Database:constructor', 'initializing database "' + this.databaseName + '", version ' + this.databaseVersion )
            
            this.db = this.openDatabase()
            this.mapper = new Mapper( this )
            
            Database.instance = this
            
        }
        
        return Database.instance
        
    }
    
    setResetState( state )
    {
        this.resetState = state
    }
    
    getResetState()
    {
        return this.resetState
    }
    
    /**
     * openDatabase
     * - opens indexeddb connection and returns database promise
     * @param dropflag (boolean, drop database or not)
     * @returns {Promise<IDBPDatabase<unknown>>}
     */
    openDatabase( dropflag )
    {
        
        let fresh = false
        let dbPromise = openDB( this.databaseName, this.databaseVersion, {
            upgrade( db, oldVersion, newVersion, upgradeDB )
            {
                
                fresh = true
                
                switch ( oldVersion ) {
                    case 0:
                        upgradeDB.db.createObjectStore( 'objects' )
                    // falls through
                    case 1:
                        upgradeDB.db.createObjectStore( 'metas' )
                    // falls through
                    case 2:
                        upgradeDB.db.createObjectStore( 'maps' )
                    // falls through
                    case 3:
                        upgradeDB.db.createObjectStore( 'queue' )
                    // falls through
                    case 4:
                        upgradeDB.db.createObjectStore( 'types' )
                    // falls through
                    case 5:
                        upgradeDB.db.createObjectStore( 'storage' )
                    // falls through
                    case 6:
                        upgradeDB.db.createObjectStore( 'settings' )
                    // falls through
                    case 7:
                        upgradeDB.db.createObjectStore( 'references' )
                    // falls through
                    case 8:
                        upgradeDB.db.createObjectStore( 'uploads' )
                    // falls through
                    case 9:
                        upgradeDB.db.createObjectStore( 'deletions' )
                    // falls through
                    case 10:
                        upgradeDB.db.createObjectStore( 'messages' )
                    // falls through
                    case 11:
                        upgradeDB.db.createObjectStore( 'triggers' )
                    // falls through
                    case 12:
                        upgradeDB.db.createObjectStore( 'rights' )
                    // falls through
                    case 13:
                        upgradeDB.db.createObjectStore( 'rekey' )
                    // falls through
                    case 14:
                        upgradeDB.db.createObjectStore( 'statistics' )
                    // falls through
                    case 15:
                        upgradeDB.db.createObjectStore( 'templates' )
                    // falls through
                    case 16:
                        upgradeDB.db.createObjectStore( 'bubbles' )
                    // falls through
                    case 17:
                        upgradeDB.db.createObjectStore( 'socketmessages' )
                    // falls through
                    case 18:
                        upgradeDB.db.createObjectStore( 'backgrounds' )
                    // falls through
                    case 19:
                        upgradeDB.db.createObjectStore( 'shares' )
                    // falls through
                    case 20:
                        upgradeDB.db.createObjectStore( 'sharequeue' )
                    // falls through
                    case 21:
                        upgradeDB.db.createObjectStore( 'bubble_details' )
                    // falls through
                    case 22:
                        upgradeDB.db.createObjectStore( 'pinning' )
                    // falls through
                    case 23:
                        upgradeDB.db.createObjectStore( 'messagespooler' )
                    // falls through
                    case 24:
                        upgradeDB.db.createObjectStore( 'patches' )
                    // falls through
                    case 25:
                        upgradeDB.db.createObjectStore( 'queuedJobs' )
                    // falls through
                    case 26:
                        upgradeDB.db.createObjectStore( 'freezer' )
                    // falls through
                    case 27:
                        upgradeDB.db.createObjectStore( 'hiding' )
                    // falls through
                    case 28:
                        upgradeDB.db.createObjectStore( 'slotTemplates' )
                    // falls through
                    case 29:
                        upgradeDB.db.createObjectStore( 'shadowCopies' )
                    // falls through
                }
                
            }
        } ).then( ( db ) =>
        {
            
            this.setState( 'mxCache-initialize-after-sync', fresh )
            
            if ( undefined !== dropflag ) {
                
                this.logger.log( 'database dropflag is set: will destroy objectstores' )
                
                db.close()
                
                setTimeout( () =>
                {
                    
                    let req = indexedDB.deleteDatabase( this.databaseName )
                    
                    req.onsuccess = () =>
                    {
                        this.logger.log( 'database dropped' )
                    }
                    
                }, 10 )
                
            }
            return db
            
        } )
        
        return dbPromise
        
    }
    
    truncateTables( tables )
    {
        
        return new Promise(
            resolve =>
            {
                
                if ( 0 < tables.length ) {
                    
                    let table = tables[ 0 ]
                    this.db.then( database =>
                    {
                        
                        const transaction = database.transaction( table, 'readwrite' )
                        transaction.objectStore( table ).clear()
                        tables.shift()
                        return resolve( this.truncateTables( tables ) )
                        
                    } )
                    
                } else {
                    return resolve( true )
                }
                
            } )
        
    }
    
    truncateStorage()
    {
        return new Promise( resolve =>
        {
            
            let todo = [
                'maps',
                'metas',
                'types',
                'objects',
                'queue',
                'uploads',
                'deletions',
                'references',
                'messages',
                'rights',
                'triggers',
                'rekey',
                'statistics',
                'templates',
                'bubbles',
                'socketmessages',
                'shares',
                'sharequeue',
                'bubble_details',
                'pinning',
                'messagespooler',
                'patches',
                'hiding'
            ]
            
            this.truncateTables( todo )
                .then( () =>
                {
                    return resolve()
                } )
            
        } )
    }
    
    truncateAll( stayopen )
    {
        
        return new Promise(
            resolve =>
            {
                
                let todo = [
                    'maps',
                    'metas',
                    'types',
                    'objects',
                    'queue',
                    'storage',
                    'settings',
                    'uploads',
                    'deletions',
                    'references',
                    'messages',
                    'rights',
                    'triggers',
                    'pinning',
                    'rekey',
                    'statistics',
                    'templates',
                    'bubbles',
                    'socketmessages',
                    'backgrounds',
                    'shares',
                    'messagespooler',
                    'patches',
                    'queuedJobs',
                    'hiding'
                ]
                
                this.truncateTables( todo )
                    .then( () =>
                    {
                        
                        if ( true === stayopen ) {
                            return resolve()
                        } else {
                            return resolve( this.closeDatabase() )
                        }
                        
                    } )
                
            } )
        
    }
    
    /**
     * closeDatabase
     * - closes indexeddb connection
     * @returns {Promise<unknown>}
     */
    closeDatabase()
    {
        
        return new Promise( resolve =>
        {
            
            this.db.then( db =>
                {
                    db.close()
                    this.logger.clog( 'Database:closeDatabase', 'closed database connection.' )
                    return resolve()
                } )
                .catch( error =>
                {
                    this.logger.cerror( 'Database:closeDatabase', 'error closing database connection: ' + error )
                } )
            
        } )
        
    }
    
    /**
     * delete
     * @param storage
     * @param key
     * @returns {Promise<DB | never>}
     */
    delete( storage, key )
    {
        return new Promise( ( resolve, reject ) =>
        {
            
            this.db.then( database =>
                {
                    
                    const transaction = database.transaction( storage, 'readwrite' )
                    transaction.objectStore( storage ).delete( key )
                    return resolve()
                    
                } )
                .catch( () =>
                {
                    return reject()
                } )
            
        } )
    }
    
    /* eslint-disable */
    deleteReference( key )
    {
        return new Promise( resolve =>
        {
            
            if ( undefined === key ) {
                return resolve()
            }
            
            this.readAllObjects( 'references' )
                .then( list =>
                {
                    
                    if ( 0 < list.length ) {
                        let found = false
                        for ( let l in list ) {
                            let container = list[ l ]
                            if ( container.key === key && container.item.length === 1 ) {
                                found = true
                                this.delete( 'references', key )
                                    .then( () =>
                                    {
                                        return resolve()
                                    } )
                            } else {
                                let index = container.item.indexOf( key )
                                if ( -1 < index ) {
                                    found = true
                                    container.item.splice( index, 1 )
                                    this.writeFlatReference( container.item, key )
                                        .then( () =>
                                        {
                                            return resolve()
                                        } )
                                }
                            }
                        }
                        if ( !found ) {
                            return resolve()
                        }
                    } else {
                        return resolve()
                    }
                    
                } )
                .catch( () =>
                {
                    return resolve()
                } )
            
        } )
    }
    
    deleteObject( key )
    {
        return new Promise( ( resolve, reject ) =>
        {
            
            this.delete( 'objects', key )
                .then( () =>
                {
                    this.delete( 'types', key )
                        .then( () =>
                        {
                            this.delete( 'metas', key )
                                .then( () =>
                                {
                                    this.deleteReference( key )
                                        .then( () =>
                                        {
                                            
                                            return resolve()
                                            
                                        } )
                                } )
                        } )
                } )
                .catch( () =>
                {
                    return reject()
                } )
            
        } )
    }
    
    deleteList( storage, keys )
    {
        return new Promise( ( resolve ) =>
        {
            
            this.db.then( database =>
            {
                
                const transactions = database.transaction( storage, 'readwrite' )
                for ( let k in keys ) {
                    
                    let localId = keys[ k ]
                    
                    transactions.objectStore( storage )
                                .delete( localId )
                                .catch( () =>
                                {
                                } )
                    
                }
                
                transactions
                    .done
                    .then( () =>
                    {
                        
                        return resolve()
                        
                    } )
                
            } )
            
        } )
    }
    
    deleteObjectsList( keys )
    {
        return new Promise( ( resolve ) =>
        {
            
            this.db.then( database =>
            {
                
                this.deleteList( 'objects', keys )
                    .then( () =>
                    {
                        this.deleteList( 'types', keys )
                            .then( () =>
                            {
                                this.deleteList( 'metas', keys )
                                    .then( () =>
                                    {
                                        this.deleteList( 'references', keys )
                                            .then( () =>
                                            {
                                                return resolve()
                                            } )
                                    } )
                            } )
                    } )
                
            } )
            
        } )
    }
    
    
    write( storeName, key, value )
    {
        
        return new Promise( ( resolve, reject ) =>
        {
            
            this.db.then( database =>
                {
                    
                    const transaction = database.transaction( storeName, 'readwrite' )
                    
                    if ( key === undefined ) {
                        return reject( 'ERROR_KEY_MISSING' )
                    } else {
                        
                        let clone = JSON.parse( JSON.stringify( value ) )
                        transaction.objectStore( storeName ).put( clone, key )
                                   .finally( success =>
                                   {
                                       return resolve( success )
                                   } )
                                   .catch( () =>
                                   {
                                       return reject( 'ERROR WRITE DB' )
                                   } )
                    }
                    
                } )
                .catch( error =>
                {
                    return reject( 'ERROR_DB_OPEN:' + error )
                } )
            
        } )
        
    }
    
    read( storeName, key )
    {
        
        return new Promise( ( resolve, reject ) =>
        {
            
            this.db.then( database =>
                {
                    
                    const transaction = database.transaction( storeName, 'readonly' )
                                                .objectStore( storeName )
                    
                    transaction.get( key )
                               .then( ( obj ) =>
                               {
                                   
                                   if ( undefined !== obj ) {
                                       return resolve( obj )
                                   }
                                   
                                   return reject( 'ERROR_NOT_FOUND' )
                                   
                               } )
                               .catch( e =>
                               {
                                   return reject( e )
                               } )
                    
                } )
                .catch( () =>
                {
                    return reject( 'ERROR_DB_READ' )
                } )
            
        } )
        
    }
    
    /**
     * writeStorage
     * @param key
     * @param value
     * @returns {Promise<any>}
     */
    writeStorage( key, value )
    {
        
        return new Promise(
            ( resolve, reject ) =>
            {
                
                this.write( 'storage', key, value )
                    .then( () =>
                    {
                        return resolve()
                    } )
                    .catch( () =>
                    {
                        
                        if ( true !== this.getState( 'app-resetting' ) ) {
                            
                            this.eventManager
                                .dispatch( 'ui-blocker-showBlocker',
                                    {
                                        mode:   'error',
                                        title:  'Oh nein!',
                                        text:   '<strong>entzettelt</strong> funktioniert auf deinem Browser leider nicht! Damit unsere App funktionieren kann, benötigt sie Zugriff auf die lokale Datenbank deines Browsers, damit deine Daten auch offline verfügbar sind.<br/><br/><strong>Hinweis: Wenn du den "Private Mode" deines Browsers verwendest, kann das bereits der Grund für diesen Fehler sein.</strong>',
                                        locked: true
                                    } )
                            
                        }
                        
                        return reject( 'ERROR WRITE STORAGE' )
                        
                    } )
                
            } )
    }
    
    /**
     * readStorage
     * @param key
     * @returns {Promise<any>}
     */
    readStorage( key )
    {
        
        return new Promise(
            ( resolve, reject ) =>
            {
                
                this.read( 'storage', key )
                    .then( result =>
                    {
                        return resolve( result )
                    } )
                    .catch( () =>
                    {
                        return reject( 'ERROR WRITE STORAGE' )
                    } )
                
            } )
    }
    
    /**
     * readObject
     * @param key
     * @returns {Promise<any>}
     */
    readObject( key )
    {
        
        return new Promise(
            ( resolve, reject ) =>
            {
                
                this.read( 'objects', key )
                    .then( data =>
                    {
                        
                        let result = {
                            id:     key,
                            object: data
                        }
                        
                        return resolve( result )
                        
                    } )
                    .catch( () =>
                    {
                        return reject( 'ERROR_NOT_FOUND' )
                    } )
                
            } )
    }
    
    /**
     * readObjectList -> historic call -> redirects to readObjectsByIdList
     * @param list
     * @returns {Promise<unknown>}
     */
    readObjectList( list )
    {
        return new Promise( resolve =>
        {
            this.readObjectsByIdList( list )
                .then( result =>
                {
                    return resolve( result )
                } )
        } )
    }
    
    _containsKey( keySet, uuid )
    {
        for ( let k in keySet ) {
            if ( keySet[ k ].uuid === uuid ) {
                return true
            }
        }
        return false
    }
    
    _prepareKeysets( list, keyList )
    {
        
        let updateKeys = {}
        
        for ( let l in list ) {
            if ( undefined === updateKeys[ l ] ) {
                updateKeys[ l ] = []
            }
            for ( let i in list[ l ] ) {
                
                let key = list[ l ][ i ]
                if ( !this._containsKey( updateKeys[ l ], key.colleagueUuid ) ) {
                    updateKeys[ l ].push( {
                        uuid: key.colleagueUuid,
                        key:  key.key
                    } )
                }
            }
        }
        
        for ( let l in keyList ) {
            if ( undefined === updateKeys[ l ] ) {
                updateKeys[ l ] = []
            }
            for ( let k in keyList[ l ] ) {
                let key = keyList[ l ][ k ]
                if ( !this._containsKey( updateKeys[ l ], key.uuid ) ) {
                    updateKeys[ l ].push( {
                        uuid: key.uuid,
                        key:  key.key
                    } )
                }
            }
        }
        
        return updateKeys
        
    }
    
    removeObjectKeys( list )
    {
        return new Promise( ( resolve, reject ) =>
        {
            
            this.db.then( database =>
            {
                
                let keyList     = {},
                    elementList = {}
                const readTransaction = database.transaction( 'objects', 'readonly' )
                
                for ( let l in list ) {
                    
                    let localId = list[ l ].localId
                    
                    readTransaction.objectStore( 'objects' )
                                   .get( localId )
                                   .then( item =>
                                   {
                                       keyList[ localId ] = item.keys
                                       elementList[ localId ] = item
                                   } )
                                   .catch( () =>
                                   {
                                   } )
                    
                }
                
                readTransaction.done.then( () =>
                {
                    
                    const writeTransaction = database.transaction( 'objects', 'readwrite' )
                    
                    for ( let e in elementList ) {
                        
                        let elementClone = JSON.parse( JSON.stringify( elementList[ e ] ) ),
                            newKeySet    = []
                        
                        for ( let k in elementClone.keys ) {
                            let found = false
                            for ( let l in list ) {
                                if ( elementClone.keys[ k ].uuid === list[ l ].colleagueUuid ) {
                                    found = true
                                }
                            }
                            if ( !found ) {
                                newKeySet.push( elementClone.keys[ k ] )
                            }
                        }
                        
                        elementClone.keys = newKeySet
                        writeTransaction.objectStore( 'objects' )
                                        .put( elementClone, e )
                        
                    }
                    
                    writeTransaction.done.then( () =>
                    {
                        
                        return resolve()
                        
                    } )
                    
                } )
                
            } )
        } )
        
    }
    
    appendObjectKeys( list, ownUuid )
    {
        return new Promise( ( resolve, reject ) =>
        {
            
            this.db.then( database =>
            {
                
                let keyList     = {},
                    elementList = {}
                
                const readTransaction = database.transaction( 'objects', 'readonly' )
                
                for ( let l in list ) {
                    readTransaction.objectStore( 'objects' )
                                   .get( l )
                                   .then( item =>
                                   {
                                       keyList[ l ] = item.keys
                                       elementList[ l ] = item
                                   } )
                                   .catch( () =>
                                   {
                                   } )
                    
                }
                
                readTransaction.done.then( () =>
                {
                    
                    let updateKeys = this._prepareKeysets( list, keyList )
                    const writeTransaction = database.transaction( 'objects', 'readwrite' )
                    
                    for ( let e in elementList ) {
                        
                        let elementClone = JSON.parse( JSON.stringify( elementList[ e ] ) )
                        elementClone.keys = updateKeys[ e ]
                        
                        writeTransaction.objectStore( 'objects' )
                                        .put( elementClone, e )
                        
                    }
                    
                    writeTransaction.done.then( () =>
                    {
                        
                        return resolve()
                        
                    } )
                    
                } )
                
            } )
            
        } )
    }
    
    _syncObjects( database, objects )
    {
        return new Promise( ( resolve, reject ) =>
        {
            
            const transaction = database.transaction( 'objects', 'readwrite' )
            
            for ( let o in objects ) {
                transaction.objectStore( 'objects' ).put( objects[ o ].object, objects[ o ].localId )
            }
            
            transaction.oncomplete = () =>
            {
                return resolve()
            }
            
            transaction.onerror = ( e ) =>
            {
                return reject( e )
            }
            
        } )
        
    }
    
    _syncTypes( database, types )
    {
        return new Promise( ( resolve, reject ) =>
        {
            
            const transaction = database.transaction( 'types', 'readwrite' )
            
            for ( let t in types ) {
                transaction.objectStore( 'types' ).put( types[ t ].type, types[ t ].localId )
            }
            
            transaction.oncomplete = () =>
            {
                return resolve()
            }
            
            transaction.onerror = ( e ) =>
            {
                return reject( e )
            }
            
        } )
        
    }
    
    _syncReferences( references )
    {
        
        return new Promise( ( resolve, reject ) =>
        {
            
            let referenceList = {}
            for ( let r in references ) {
                if ( references[ r ].referenceKey ) {
                    if ( undefined === referenceList[ references[ r ].referenceKey ] ) {
                        referenceList[ references[ r ].referenceKey ] = []
                    }
                    referenceList[ references[ r ].referenceKey ].push( references[ r ].localId )
                }
            }
            
            this.writeReferencesList( referenceList )
                .then( () =>
                {
                    return resolve()
                } )
                .catch( e =>
                {
                    return reject( e )
                } )
            
        } )
        
    }
    
    writeSyncObjectList( list )
    {
        
        return new Promise( resolve =>
        {
            
            if ( 0 === list.length ) {
                return resolve()
            } else {
                this.db.then( database =>
                {
                    
                    let objects    = [],
                        types      = [],
                        references = []
                    
                    for ( let l in list ) {
                        objects.push( {
                            localId: list[ l ].localId,
                            object:  list[ l ].object
                        } )
                        types.push( {
                            localId: list[ l ].localId,
                            type:    list[ l ].type
                        } )
                        
                        if ( undefined !== list[ l ].referenceKey ) {
                            references.push( {
                                localId:      list[ l ].localId,
                                referenceKey: list[ l ].referenceKey
                            } )
                        }
                    }
                    
                    this._syncObjects( database, objects )
                        .then( () =>
                        {
                            
                            this._syncTypes( database, types )
                                .then( () =>
                                {
                                    
                                    this._syncReferences( references )
                                        .then( () =>
                                        {
                                            
                                            return resolve()
                                            
                                        } )
                                        .catch( () =>
                                        {
                                            return resolve()
                                        } )
                                    
                                } )
                                .catch( () =>
                                {
                                    return resolve()
                                } )
                            
                        } )
                        .catch( () =>
                        {
                            return resolve()
                        } )
                    
                } )
            }
            
        } )
        
    }
    
    /*eslint-disable*/
    deleteSharesList( list )
    {
        return new Promise( resolve =>
        {
            
            if ( 0 === list.length ) {
                return resolve()
            }
            
            this.db.then( database =>
            {
                
                let existing = {},
                    changes  = false
                
                const readTransaction = database.transaction( 'shares', 'readonly' )
                for ( let l in list ) {
                    
                    readTransaction.objectStore( 'shares' )
                                   .get( list[ l ].localId )
                                   .then( share =>
                                   {
                                       if ( undefined !== share ) {
                                           existing[ list[ l ].localId ] = share.split( /\,/g )
                                       }
                                   } )
                                   .catch( () =>
                                   {
                                   } )
                    
                }
                
                readTransaction.done.then( () =>
                {
                    
                    for ( let l in list ) {
                        if ( undefined !== existing[ list[ l ].localId ]
                             && -1 < existing[ list[ l ].localId ].indexOf( '' + list[ l ].idColleague ) ) {
                            let position = existing[ list[ l ].localId ].indexOf( '' + list[ l ].idColleague )
                            existing[ list[ l ].localId ].splice( position, 1 )
                            changes = true
                        }
                    }
                    
                    if ( !changes ) {
                        return resolve()
                    }
                    
                    const writeTransaction = database.transaction( 'shares', 'readwrite' )
                    for ( let e in existing ) {
                        if ( 0 < existing[ e ].length ) {
                            writeTransaction.objectStore( 'shares' )
                                            .put( existing[ e ].join( ',' ), e )
                        } else {
                            writeTransaction.objectStore( 'shares' )
                                            .delete( e )
                        }
                    }
                    
                    writeTransaction.done.then( () =>
                    {
                        return resolve()
                    } )
                    
                } )
                
            } )
            
        } )
    }
    
    writeList( storage, list )
    {
        return new Promise( resolve =>
        {
            
            if ( 0 === list.length ) {
                return resolve()
            }
            
            this.db.then( database =>
            {
                
                const transaction = database.transaction( storage, 'readwrite' )
                for ( let l in list ) {
                    
                    transaction.objectStore( storage )
                               .put( list[ l ].item, list[ l ].key )
                    
                }
                
                transaction.done.then( () =>
                {
                    
                    return resolve()
                    
                } )
                
            } )
            
        } )
    }
    
    writeSharesList( list )
    {
        return new Promise( resolve =>
        {
            
            if ( 0 === list.length ) {
                return resolve()
            }
            
            this.db.then( database =>
            {
                
                let existing = {},
                    changes  = false
                
                const readTransaction = database.transaction( 'shares', 'readonly' )
                for ( let l in list ) {
                    
                    readTransaction.objectStore( 'shares' )
                                   .get( list[ l ].localId )
                                   .then( share =>
                                   {
                                       if ( undefined !== share ) {
                                           existing[ list[ l ].localId ] = share.split( /\,/g )
                                       }
                                   } )
                                   .catch( () =>
                                   {
                                   } )
                    
                }
                
                readTransaction.done.then( () =>
                {
                    
                    for ( let l in list ) {
                        if ( undefined === existing[ list[ l ].localId ] ) {
                            existing[ list[ l ].localId ] = []
                        }
                        
                        if ( -1 === existing[ list[ l ].localId ].indexOf( '' + list[ l ].idColleague ) ) {
                            existing[ list[ l ].localId ].push( '' + list[ l ].idColleague )
                            changes = true
                        }
                    }
                    
                    if ( !changes ) {
                        return resolve()
                    }
                    
                    const writeTransaction = database.transaction( 'shares', 'readwrite' )
                    for ( let e in existing ) {
                        writeTransaction.objectStore( 'shares' )
                                        .put( existing[ e ].join( ',' ), e )
                    }
                    
                    writeTransaction.done.then( () =>
                    {
                        return resolve()
                    } )
                    
                } )
                
            } )
            
        } )
    }
    
    /*eslint-disable*/
    /**
     * writeObject
     * @param object
     * @param id
     * @param type
     * @returns {Promise<DB | never>}
     */
    writeObject( object, id, type )
    {
        
        let debug = false
        
        if ( id === undefined ) {
            id = this.uuid.generate()
        }
        
        return new Promise(
            ( resolve, reject ) =>
            {
                
                this.write( 'objects', id, JSON.parse( JSON.stringify( object ) ) )
                    .then( () =>
                    {
                        if ( undefined !== type ) {
                            this.mapper.add( type, id )
                                .then( () =>
                                {
                                    return resolve( this.writeType( id, type ) )
                                } )
                        }
                    } )
                    .then( () =>
                    {
                        
                        return resolve( this.writeMeta( id ) )
                        
                    } )
                    .then( () =>
                    {
                        
                        return resolve()
                        
                    } )
                    .catch( exception =>
                    {
                        this.logger.error( 'exception caught: ' + exception )
                        return reject( 'DATABASE ERROR: ' + exception )
                    } )
                
            } )
        
    }
    
    /**
     * writeType
     * @param id
     * @param type
     * @returns {Promise<void | never>}
     */
    writeType( id, type )
    {
        
        return new Promise(
            ( resolve, reject ) =>
            {
                this.write( 'types', id, type )
                    .then( () =>
                    {
                        
                        return resolve()
                        
                    } )
                    .catch( error =>
                    {
                        
                        return reject( error )
                        
                    } )
            } )
        
    }
    
    readType( id )
    {
        return new Promise( ( resolve, reject ) =>
        {
            
            this.read( 'types', id )
                .then( result =>
                {
                    
                    return resolve( result )
                    
                } )
                .catch( () =>
                {
                    return reject( 'ERROR_TYPE_NOT_FOUND' )
                } )
            
        } )
    }
    
    /**
     * writeMeta
     * @param id
     * @param authorName
     * @param updaterName
     * @param version
     * @returns {Promise<any>}
     */
    writeMeta( id, authorName, updaterName, version )
    {
        
        return new Promise( ( resolve, reject ) =>
        {
            
            let meta = {
                id:        id,
                createdOn: Date.now(),
                createdBy: authorName !== undefined ? authorName : 'ERROR_UNKNOWN',
                updatedOn: Date.now(),
                updatedBy: updaterName !== undefined ? updaterName : 'ERROR_UNKNOWN',
                version:   version !== undefined ? version : 1,
                dbVersion: version !== undefined ? version : 1
            }
            
            this.write( 'metas', id, meta )
                .then( () =>
                {
                    
                    return resolve()
                    
                } )
                .catch( error =>
                {
                    
                    return reject( error )
                    
                } )
            
        } )
        
    }
    
    /**
     * readMap
     * @param id
     * @returns {Promise<any>}
     */
    readMap( id )
    {
        return new Promise( ( resolve, reject ) =>
        {
            
            this.read( 'maps', id )
                .then( result =>
                {
                    return resolve( result )
                } )
                .catch( () =>
                {
                    return reject( 'ERROR_NOT_FOUND' )
                } )
            
        } )
    }
    
    /**
     * writeMap
     * @param id
     * @param map
     * @returns {Promise<DB | never>}
     */
    writeMap( id, map )
    {
        
        return new Promise( ( resolve, reject ) =>
        {
            
            this.write( 'maps', id, JSON.parse( JSON.stringify( map ) ) )
                .then( () =>
                {
                    return resolve()
                } )
                .catch( error =>
                {
                    return reject( error )
                } )
            
        } )
        
    }
    
    /**
     * appendMap
     * @param id the mapping key
     * @param localId array||string an array of localIds or a single localId
     * @returns {Promise<unknown>}
     */
    appendMap( id, localId )
    {
        return new Promise( ( resolve, reject ) =>
        {
            
            this.read( 'maps', id )
                .then( list =>
                {
                    
                    let changes = false
                    
                    if ( Array.isArray( localId ) ) {
                        for ( let i in localId ) {
                            let check = localId[ i ]
                            if ( -1 < list.indexOf( check ) ) {
                                changes = true
                                list.push( check )
                            }
                        }
                    } else {
                        if ( -1 < list.indexOf( localId ) ) {
                            changes = true
                            list.push( localId )
                        }
                    }
                    
                    if ( changes === true ) {
                        this.write( 'maps', id, JSON.parse( JSON.stringify( list ) ) )
                            .then( () =>
                            {
                                return resolve()
                            } )
                            .catch( error =>
                            {
                                return reject( error )
                            } )
                    } else {
                        return resolve()
                    }
                    
                } )
                .catch( () =>
                {
                    return resolve()
                } )
            
        } )
        
    }
    
    /**
     * readAllObjects
     * @param storage
     * @returns {Promise<unknown>}
     */
    readAllObjects( storage )
    {
        
        return new Promise( ( resolve, reject ) =>
        {
            
            let start = Date.now()
            this.db.then( database =>
            {
                
                const transactKeys = database.transaction( storage, 'readonly' ),
                      storeKeys    = transactKeys.objectStore( storage )
                
                let items = []
                
                storeKeys.getAllKeys()
                         .then( result =>
                         {
                             
                             for ( let r in result ) {
                                 items.push( {
                                     key:  result[ r ],
                                     item: null
                                 } )
                             }
                             
                             const transaction = database.transaction( storage, 'readonly' )
                             for ( let l in items ) {
                                 
                                 transaction.objectStore( storage )
                                            .get( items[ l ].key )
                                            .then( object =>
                                            {
                                                items[ l ].item = object
                                            } )
                                            .catch( () =>
                                            {
                                            } )
                                 
                             }
                             transaction.done.then( () =>
                             {
                                 
                                 return resolve( items )
                                 
                             } )
                             
                             
                         } )
                         .catch( error =>
                         {
                             this.logger.cerror( 'Database::readAllObjects', 'objectStore.getAllKeys failed:', error )
                             return reject( error )
                         } )
                
            } )
            
        } )
        
    }
    
    
    /**
     * readAllObjectsAndTypesLike
     * @param storage
     * @returns {Promise<unknown>}
     */
    readAllObjectsAndTypesLike( storage, like )
    {
        
        return new Promise( ( resolve, reject ) =>
        {
            
            let start = Date.now()
            this.db.then( database =>
            {
                
                const transactKeys = database.transaction( storage, 'readonly' ),
                      storeKeys    = transactKeys.objectStore( storage )
                
                let items = []
                
                storeKeys.getAllKeys()
                         .then( result =>
                         {
                             
                             for ( let r in result ) {
                                 if ( 0 === result[ r ].indexOf( like ) ) {
                                     items.push( {
                                         key:  result[ r ],
                                         item: null
                                     } )
                                 }
                             }
                             
                             const transaction = database.transaction( storage, 'readonly' )
                             for ( let l in items ) {
                                 
                                 transaction.objectStore( storage )
                                            .get( items[ l ].key )
                                            .then( object =>
                                            {
                                                items[ l ].item = object
                                            } )
                                            .catch( () =>
                                            {
                                            } )
                                 
                             }
                             
                             transaction.done.then( () =>
                             {
                                 const transactionType = database.transaction( 'types', 'readonly' )
                                 for ( let l in items ) {
                                     transactionType.objectStore( 'types' )
                                                    .get( items[ l ].key )
                                                    .then( type =>
                                                    {
                                                        items[ l ].type = type
                                                    } )
                                                    .catch( () =>
                                                    {
                                                    } )
                                 }
                                 transactionType.done.then( () =>
                                 {
                                     return resolve( items )
                                 } )
                             } )
                             
                         } )
                         .catch( error =>
                         {
                             this.logger.cerror( 'Database::readAllObjects', 'objectStore.getAllKeys failed:', error )
                             return reject( error )
                         } )
                
            } )
            
        } )
        
    }
    
    _readAllObjects( storage )
    {
        
        return new Promise( ( resolve, reject ) =>
        {
            
            let items = []
            
            this.db.then( database =>
            {
                
                const transaction = database.transaction( storage, 'readonly' ),
                      store       = transaction.objectStore( storage )
                
                let start = Date.now()
                
                store.getAllKeys()
                     .then( result =>
                     {
                         
                         for ( let r in result ) {
                             transaction.objectStore( storage ).get( result[ r ] )
                                        .then( object =>
                                        {
                                            items.push( {
                                                key:  result[ r ],
                                                item: object
                                            } )
                                        } )
                                        .catch( error =>
                                        {
                                            this.logger.cerror( 'Database::readAllObjects', 'objectStore.get failed:', error )
                                            return reject( error )
                                        } )
                         }
                         
                     } )
                     .catch( error =>
                     {
                         this.logger.cerror( 'Database::readAllObjects', 'objectStore.getAllKeys failed:', error )
                         return reject( error )
                     } )
                
                transaction.done.then( () =>
                           {
                               return resolve( items )
                           } )
                           .catch( error =>
                           {
                               this.logger.cerror( 'Database::readAllObjects', 'transaction failed:', error )
                               return reject( error )
                           } )
                
            } )
            
        } )
        
    }
    
    /**
     * readSettings
     * @returns {Promise<any>}
     */
    readSettings()
    {
        
        return this.readAllObjects( 'settings' )
        
    }
    
    /**
     * readSetting
     * @param key
     * @returns {Promise<any>}
     */
    readSetting( key )
    {
        return new Promise( ( resolve, reject ) =>
        {
            
            this.read( 'settings', key )
                .then( value =>
                {
                    return resolve( value )
                } )
                .catch( () =>
                {
                    return reject( 'ERROR_NOT_FOUND' )
                } )
            
        } )
    }
    
    /**
     * writeSetting
     * @param key
     * @param value
     * @returns {Promise<unknown>}
     */
    writeSetting( key, value )
    {
        
        return new Promise( ( resolve, reject ) =>
        {
            this.write( 'settings', key, value )
                .then( () =>
                {
                    return resolve()
                } )
                .catch( error =>
                {
                    return reject( 'ERROR_WRITE_SETTING:' + error )
                } )
        } )
        
    }
    
    writeSettingsList( list )
    {
        
        return new Promise( resolve =>
        {
            
            let start = Date.now()
            this.db.then( database =>
            {
                
                const transaction = database.transaction( 'settings', 'readwrite' )
                for ( let s in list ) {
                    
                    transaction.objectStore( 'settings' )
                               .put( list[ s ], s )
                    
                }
                
                transaction.done.then( () =>
                {
                    return resolve()
                } )
                
            } )
        } )
        
    }
    
    /*DBG*/
    readObjectsByIdList( list )
    {
        
        return new Promise( resolve =>
        {
            
            let results = []
            this.db.then( database =>
            {
                
                const transaction = database.transaction( 'objects', 'readonly' )
                for ( let l in list ) {
                    
                    transaction.objectStore( 'objects' ).get( list[ l ] )
                               .then( object =>
                               {
                                   results.push( {
                                       id:     list[ l ],
                                       object: object
                                   } )
                               } )
                    
                }
                
                transaction.done.then( () =>
                {
                    return resolve( results )
                } )
                
            } )
            
        } )
        
    }
    
    verifyObjectIdsByIdList( list )
    {
        
        return new Promise( resolve =>
        {
            
            let results = []
            this.db.then( database =>
            {
                
                const transaction = database.transaction( 'objects', 'readonly' )
                for ( let l in list ) {
                    
                    transaction.objectStore( 'objects' ).get( list[ l ] )
                               .then( () =>
                               {
                                   results.push( list[ l ] )
                               } )
                    
                }
                
                transaction.done.then( () =>
                {
                    return resolve( results )
                } )
                
            } )
            
        } )
        
    }
    
    
    /**
     * readAllObjectsByType
     * @alias for readAllObjectsFiltered
     * @param type
     * @returns {Promise<unknown>}
     */
    readAllObjectsByType( type )
    {
        return new Promise( ( resolve, reject ) =>
        {
            
            this.readAllObjectsFiltered( type )
                .then( list =>
                {
                    return resolve( list )
                } )
                .catch( e =>
                {
                    return reject( e )
                } )
            
        } )
    }
    
    /**
     * readAllObjectsFiltered
     * @param filter
     * @returns {Promise<any>}
     */
    readAllObjectsFiltered( filter )
    {
        
        return new Promise( ( resolve, reject ) =>
        {
            
            let lookup = []
            
            this.readAllObjects( 'types' )
                .then( allTypes =>
                {
                    
                    for ( let i in allTypes ) {
                        if ( allTypes[ i ].item === filter ) {
                            lookup.push( allTypes[ i ].key )
                        }
                    }
                    
                    this.readObjectsByIdList( lookup )
                        .then( result =>
                        {
                            return resolve( result )
                        } )
                    
                } )
                .catch( error =>
                {
                    return reject( error )
                } )
            
        } )
        
    }
    
    /**
     * readReferences
     * @param key
     * @returns {Promise<any>}
     */
    readReferences( key )
    {
        return new Promise(
            ( resolve, reject ) =>
            {
                return this.read( 'references', key, true )
                           .then( result =>
                           {
                               return resolve( result )
                           } )
                           .catch( error =>
                           {
                               return reject( error )
                           } )
            } )
    }
    
    readReferencesAndTypes( key )
    {
        return new Promise( ( resolve, reject ) =>
        {
            
            let items = []
            
            this.db.then( database =>
            {
                
                this.read( 'references', key, true )
                    .then( result =>
                    {
                        
                        const transaction = database.transaction( 'types', 'readonly' )
                        for ( let r in result ) {
                            
                            transaction.objectStore( 'types' )
                                       .get( result[ r ] )
                                       .then( type =>
                                       {
                                           items.push( {
                                               item: result[ r ],
                                               type: type
                                           } )
                                           
                                       } )
                                       .catch( () =>
                                       {
                                       } )
                            
                        }
                        
                        transaction.done
                                   .then( () =>
                                   {
                                       
                                       return resolve( items )
                                       
                                   } )
                        
                    } )
                    .catch( error =>
                    {
                        return reject( error )
                    } )
                
            } )
            
        } )
    }
    
    /**
     * writeReference
     * @param localId
     * @returns {Promise<any>}
     */
    writeReference( localId, key )
    {
        
        return new Promise(
            ( resolve, reject ) =>
            {
                if ( 'create' === key ) {
                    return resolve()
                }
                
                let add = []
                
                this.readReferences( key )
                    .then( result =>
                    {
                        
                        if ( -1 == result.indexOf( localId ) ) {
                            add.push( localId )
                        }
                        let references = add.concat( result )
                        
                        this.write( 'references', key, references )
                            .then( result =>
                            {
                                return resolve( result )
                            } )
                            .catch( error =>
                            {
                                return reject( error )
                            } )
                    } )
                    .catch( () =>
                    {
                        
                        add.push( localId )
                        this.write( 'references', key, add )
                            .then( result =>
                            {
                                return resolve( result )
                            } )
                            .catch( error =>
                            {
                                return reject( error )
                            } )
                        
                    } )
            } )
        
    }
    
    writeReferencesList( list )
    {
        
        return new Promise( ( resolve, reject ) =>
        {
            
            this.db.then( database =>
            {
                
                const transaction = database.transaction( 'references', 'readwrite' )
                for ( let [ key, value ] of Object.entries( list ) ) {
                    
                    transaction.objectStore( 'references' ).get( key )
                               .then( list =>
                               {
                                   
                                   let newList = [ ...list ]
                                   for ( let v in value ) {
                                       if ( -1 === newList.indexOf( value[ v ] ) ) {
                                           newList.push( value[ v ] )
                                       }
                                   }
                                   
                                   transaction.objectStore( 'references' ).put( newList, key )
                                   
                               } )
                               .catch( () =>
                               {
                                   
                                   transaction.objectStore( 'references' ).put( value, key )
                                   
                               } )
                    
                }
                
                transaction.done.then( () =>
                {
                    return resolve()
                } )
                
                transaction.onerror = ( e ) =>
                {
                    return reject( e )
                }
                
            } )
            
        } )
        
    }
    
    writeUploadsList( list )
    {
        return new Promise( ( resolve, reject ) =>
        {
            
            if ( 0 === list.length ) {
                return resolve()
            }
            
            this.db.then( database =>
            {
                
                const transaction = database.transaction( 'uploads', 'readwrite' )
                for ( let l in list ) {
                    if ( list[ l ].localId !== undefined ) {
                        transaction.objectStore( 'uploads' )
                                   .put( list[ l ].type, list[ l ].localId )
                    }
                }
                
                transaction.done.then( () =>
                {
                    return resolve()
                } )
                
                transaction.onerror = ( e ) =>
                {
                    return reject( e )
                }
                
            } )
            
        } )
    }
    
    writeObjectsList( list )
    {
        
        return new Promise( ( resolve, reject ) =>
        {
            
            this.db.then( database =>
            {
                
                const transaction = database.transaction( 'objects', 'readwrite' )
                for ( let [ key, value ] of Object.entries( list ) ) {
                    transaction.objectStore( 'objects' )
                               .put( value, key )
                }
                
                transaction.done.then( () =>
                {
                    return resolve()
                } )
                
                transaction.onerror = ( e ) =>
                {
                    return reject( e )
                }
                
            } )
            
        } )
        
    }
    
    writeTypesList( list )
    {
        
        return new Promise( ( resolve, reject ) =>
        {
            
            this.db.then( database =>
            {
                
                const transaction = database.transaction( 'types', 'readwrite' )
                for ( let [ key, value ] of Object.entries( list ) ) {
                    transaction.objectStore( 'types' )
                               .put( value, key )
                }
                
                transaction.done.then( () =>
                {
                    return resolve()
                } )
                
                transaction.onerror = ( e ) =>
                {
                    return reject( e )
                }
                
            } )
            
        } )
        
    }
    
    writeFlatReference( localId, key )
    {
        
        return new Promise( ( resolve, reject ) =>
        {
            
            this.write( 'references', key, localId )
                .then( result =>
                {
                    return resolve( result )
                } )
                .catch( error =>
                {
                    return reject( error )
                } )
            
        } )
        
    }
    
    /**
     * writeReference
     * @param localId
     * @returns {Promise<any>}
     */
    writeReferences( key, references )
    {
        
        return new Promise( ( resolve, reject ) =>
        {
            this.write( 'references', key, references )
                .then( result =>
                {
                    return resolve( result )
                } )
                .catch( error =>
                {
                    return reject( error )
                } )
        } )
        
    }
    
    readTypeCounters()
    {
        return new Promise( ( resolve, reject ) =>
        {
            
            let counters = {}
            
            this.readAllObjects( 'types' )
                .then( result =>
                {
                    
                    for ( let r in result ) {
                        let type = result[ r ].item
                        if ( undefined !== type ) {
                            counters[ type ] = undefined !== counters[ type ] ? counters[ type ] + 1 : 1
                        }
                    }
                    
                    return resolve( counters )
                    
                } )
            
        } )
    }
    
}