export default class SyncCrudMissingUploads
{
    
    constructor( parent )
    {
        
        this.p = parent
        
        this.logSign = 'SyncWorker::SyncCrud::SyncCrudMissingUploads'
        
        this.parent = this.p.parent
        this.setState = parent.setState
        
        this.minAge = 3600 * 1000
        this.graceTime = 10000
        this.iteration = 0
        
        return this
        
    }
    
    /**
     * shouldSync
     * @returns {boolean}
     */
    shouldSync()
    {
        return true
    }
    
    /*eslint-disable*/
    
    getOwnerId( list, localId )
    {
        
        for ( let l in list ) {
            if ( list[ l ].key === localId ) {
                return list[ l ].item.idOwner || this.parent.store.getters.uuid
            }
        }
        
        return this.parent.store.getters.uuid
        
    }
    
    /**
     * decryptAll
     * @param list
     */
    decryptAll( list )
    {
        return new Promise( resolve =>
        {
            
            let results = []
            
            this.parent.cryptoHelper.decryptElementDbList( list, this.parent.store.getters.uuid )
                .then( result =>
                {
                    
                    for ( let r in result ) {
                        
                        let decrypted = result[ r ].decrypted
                        
                        if ( null !== decrypted
                             && false !== decrypted
                             && undefined !== decrypted ) {
                            
                            if ( undefined === decrypted.idOwner ) {
                                decrypted.idOwner = this.getOwnerId( list, result[ r ].localId )
                            }
                            
                            results.push( {
                                localId: result[ r ].localId,
                                object:  decrypted
                            } )
                            
                        }
                    }
                    
                    return resolve( results )
                    
                } )
            
        } )
    }
    
    /**
     * listDiff
     * @param local
     * @param remote
     * @returns {Promise<unknown>}
     */
    
    /*eslint-disable*/
    listDiff( local, remote, types )
    {
        return new Promise( resolve =>
        {
            
            let matches = [],
                diffs   = {
                    notFound:         [],
                    missingRemoteIds: []
                }
            
            while ( local.length > 0 ) {
                
                let foundMatch   = false,
                    localElement = local.shift()
                
                for ( let r in remote ) {
                    if ( remote[ r ].localId === localElement.localId
                         && remote[ r ].remoteId === localElement.object.remoteId ) {
                        matches.push( {
                            remote: remote[ r ],
                            local:  localElement
                        } )
                        foundMatch = true
                        
                    }
                }
                
                if ( !foundMatch ) {
                    for ( let r in remote ) {
                        if ( remote[ r ].localId === localElement.localId
                             && remote[ r ].remoteId !== localElement.object.remoteId ) {
                            
                            foundMatch = true
                            if ( localElement.object.remoteId === undefined ) {
                                diffs.missingRemoteIds.push( {
                                    localId:  localElement.localId,
                                    type:     types.get( localElement.localId ),
                                    remoteId: remote[ r ].remoteId
                                } )
                            }
                            
                        }
                    }
                    
                }
                
                if ( !foundMatch ) {
                    diffs.notFound.push( {
                        local: localElement
                    } )
                }
                
            }
            
            return resolve( {
                matches: matches,
                diffs:   diffs
            } )
            
        } )
    }
    
    /*eslint-disable*/
    /**
     * fixDiff
     * @param diffList
     */
    fixDiff( diffList, types )
    {
        
        return new Promise( resolve =>
        {
            
            this.parent.logger.clog( this.logSign, 'fixing sync diff: ' + diffList.diffs.notFound.length + ' missing objects | checking ' + diffList.matches.length + ' updates...' )
            
            let uploadList   = [],
                fixedDiffs   = 0,
                fixedUploads = 0
            
            for ( let m in diffList.matches ) {
                
                let match         = diffList.matches[ m ],
                    testTimestamp = match.remote.timestamp + this.graceTime
                
                if ( ( match.local.object.timestamp > testTimestamp
                       && match.local.object.timestamp < ( Date.now() - this.minAge )
                       && match.local.object.timestampForced !== true )
                     || match.local.object.update > testTimestamp
                     || ( match.local.object.update > 0 && match.remote.timestamp === null ) ) {
                    
                    fixedDiffs++
                    uploadList.push( {
                        type:    types.get( match.local.localId ),
                        localId: match.local.localId
                    } )
                    
                }
                
            }
            
            for ( let n in diffList.diffs.notFound ) {
                fixedUploads++
                let match = diffList.diffs.notFound[ n ]
                uploadList.push( {
                    type:    types.get( match.local.localId ),
                    localId: match.local.localId
                } )
            }
            
            let missingRemoteIds = 0
            for ( let n in diffList.diffs.missingRemoteIds ) {
                
                missingRemoteIds++
                this.parent.eventManager
                    .dispatch( 'on-fix-remoteId', diffList.diffs.missingRemoteIds[ n ] )
                
            }
            
            this.parent.logger.clog( this.logSign, 'sending ' + fixedUploads + ' missing uploads and ' + fixedDiffs + ' weird diffs into the queue...' )
            this.parent.database
                .writeUploadsList( uploadList )
                .then( () =>
                {
                    
                    this.parent.logger.clog( this.logSign, 'done.' )
                    return resolve()
                    
                } )
            
        } )
        
    }
    
    /**
     * checkLocalLeftovers
     * @param list
     */
    checkLocalLeftovers( list )
    {
        return new Promise( resolve =>
        {
            
            this.parent.database.readAllObjects( 'objects' )
                .then( dbList =>
                {
                    
                    this.parent.database.readAllObjects( 'types' )
                        .then( typeList =>
                        {
                            
                            let types = new Map()
                            for ( let t in typeList ) {
                                types.set( typeList[ t ].key, typeList[ t ].item )
                            }
                            
                            this.decryptAll( dbList )
                                .then( decryptedResult =>
                                {
                                    
                                    this.listDiff( decryptedResult, list, types )
                                        .then( diff =>
                                        {
                                            
                                            this.fixDiff( diff, types )
                                                .then( () =>
                                                {
                                                    
                                                    return resolve()
                                                    
                                                } )
                                            
                                        } )
                                    
                                } )
                            
                        } )
                    
                } )
            
        } )
    }
    
    /**
     * checkResults
     * @param list
     * @returns {Promise<unknown>}
     */
    checkResults( list )
    {
        return new Promise( resolve =>
        {
            
            let result = []
            
            for ( let l in list ) {
                if ( null === list[ l ].tsmp_object_deleted
                     && null === list[ l ].tsmp_key_deleted ) {
                    
                    result.push( {
                        remoteId:  list[ l ].id,
                        localId:   list[ l ].id_local,
                        timestamp: null !== list[ l ].datetime_updated
                                   ? this.parent.friendlyTimestamp.timestampFromMysql(
                                this.parent.friendlyTimestamp.convertServerTimestamp( list[ l ].datetime_updated ) )
                                   : null
                    } )
                    
                }
            }
            
            this.checkLocalLeftovers( result )
                .then( () =>
                {
                    return resolve()
                } )
            
        } )
    }
    
    /**
     * prepareSyncables
     * @returns {Promise<unknown>}
     */
    prepareSyncables()
    {
        
        return new Promise( ( resolve, reject ) =>
        {
            
            let message = {
                method: 'objects.listAllKnownLocalIds'
            }
            
            this.parent.client.request( message )
                .then( response =>
                {
                    
                    return resolve( response.result )
                    
                } )
                .catch( () =>
                {
                    return reject()
                } )
            
            
        } )
        
    }
    
    /**
     * sync
     * @returns {Promise<unknown>}
     */
    sync()
    {
        
        return new Promise( resolve =>
        {
            
            this.iteration++
            
            if ( 1 === this.iteration
                 || 0 === this.iteration % 75 ) {
                
                this.parent.logger.clog( this.logSign, 'performing sync run (approximately every 5 minutes and just after the initial sync)...' )
                this.prepareSyncables()
                    .then( list =>
                    {
                        
                        this.checkResults( list )
                            .then( () =>
                            {
                                
                                return resolve()
                                
                            } )
                        
                        
                    } )
                    .catch( () =>
                    {
                        
                        this.parent.logger.clog( this.logSign, 'sync run failed - starting over on next run' )
                        this.iteration = 0
                        return resolve()
                        
                    } )
                
            } else {
                return resolve()
            }
            
        } )
        
    }
    
}