function _isSortable( what )
{
    
    if ( true === what
         || false === what
         || undefined === what
         || null === what
         || ( !isNaN( parseFloat( what ) ) && isFinite( what ) ) ) {
        return false
    }
    
    return true
    
}

function _sortDefaultAsc( a, b )
{
    
    let keyA = a.__sortId
    let keyB = b.__sortId
    
    if ( !_isSortable( keyA ) || !_isSortable( keyB ) ) {
        if ( keyA < keyB ) return -1
        if ( keyA > keyB ) return 1
        return 0
    }
    
    return keyA.localeCompare( keyB, undefined, { numeric: true, sensitivity: 'base' } )
    
}

function _sortDefaultDesc( a, b )
{
    
    let keyA = a.__sortId
    let keyB = b.__sortId
    
    if ( !_isSortable( keyA ) || !_isSortable( keyB ) ) {
        if ( keyA > keyB ) return -1
        if ( keyA < keyB ) return 1
        return 0
    }
    
    return keyB.localeCompare( keyA, undefined, { numeric: true, sensitivity: 'base' } )
    
}

export default class Sorter
{
    
    constructor( settings, eventManager )
    {
        
        if ( !Sorter.instance ) {
            
            this.settings = settings
            this.eventManager = eventManager
            this.fullInitialized = false
            
            Sorter.instance = this
            
            if ( undefined !== settings ) {
                this.initColorSort()
            }
            
            this.eventManager.add( 'after-settings-synced', () =>
            {
                this.initColorSort()
            } )
            
        }
        
        return Sorter.instance
        
    }
    
    destruct()
    {
        delete Sorter.instance
    }
    
    initColorSort()
    {
        if ( this.settings.isReady() ) {
            this.colorSort = this.settings.getSetting( 'colorSequence' )
            this.dashboardSort = this.settings.getSetting( 'dashboardSequence' )
            this.fullInitialized = true
        } else {
            setTimeout( () =>
                        {
                            this.initColorSort()
                        }, 200 )
        }
    }
    
    awaitReady()
    {
        return new Promise( resolve =>
                            {
                                if ( true === this.fullInitialized ) {
                                    return resolve()
                                } else {
                                    setTimeout( () =>
                                                {
                                                    return resolve( this.awaitReady() )
                                                }, 200 )
                                }
                            } )
    }
    
    getColorSortId( value )
    {
        return ( this.colorSort.indexOf( value ) + 1 )
    }
    
    getDashboardSortId( value )
    {
        return ( this.dashboardSort.indexOf( value ) + 1 )
    }
    
    sortObjectByValue( sortable, direction )
    {
        
        let temp = []
        let sorted = []
        let result = {}
        
        for ( let key in sortable ) {
            temp.push( {
                           __sortId: sortable[ key ],
                           original: key
                       } )
        }
        
        if ( 'ascending' === direction ) {
            sorted = temp.sort( _sortDefaultAsc )
        } else {
            sorted = temp.sort( _sortDefaultDesc )
        }
        
        for ( let i in sorted ) {
            result[ sorted[ i ].original ] = sorted[ i ].__sortId
        }
        
        return result
        
    }
    
    /*eslint-disable*/
    sortIcons( sortable )
    {
        
        let temp   = [],
            sorted = []
        
        for ( let n in sortable ) {
            temp.push( {
                           __sortId: this.getDashboardSortId( sortable[ n ].id ),
                           original: sortable[ n ]
                       } )
        }
        
        temp.sort( _sortDefaultAsc )
        
        for ( let t in temp ) {
            sorted.push( temp[ t ].original )
        }
        
        return sorted
        
    }
    
    /*eslint-disable*/
    
    sortObjects( sortable, sortby, direction, returnResult )
    {
        
        returnResult = returnResult || false
        try {
            for ( let n in sortable ) {
                if ( -1 < sortby.indexOf( '.' ) ) {
                    let t = sortby.split( '.' )
                    sortable[ n ].__sortId = sortable[ n ][ t[ 0 ] ][ t[ 1 ] ]
                } else {
                    if( undefined !== sortable[n] )
                    {
                        let sortId = sortable[ n ][ sortby ]
                        if ( 'color' == sortby ) {
                            sortId = this.getColorSortId( sortable[ n ][ sortby ] )
                        }
                        sortable[ n ].__sortId = sortId
                    }
                }
            }
            
            if ( 'ascending' === direction
                 || undefined === direction ) {
                sortable.sort( _sortDefaultAsc )
            } else {
                sortable.sort( _sortDefaultDesc )
            }
        } catch ( e ) {
         
            console.log( 'DBG > SORT ERROR', e )
            
        }
        
        if ( true === returnResult ) {
            return sortable
        }
    }
    
    getSortMap( sortable, sortby, useReference )
    {
        
        if ( undefined === sortable ) {
            return
        }
        
        let sorted = this.multiSortObjects( [ ...sortable.values() ], sortby, false, true ),
            result = []
        
        for ( let i in sorted ) {
            result.push( useReference ? sorted[ i ].referenceKey : sorted[ i ].localId )
        }
        
        return result
        
    }
    
    sortId( field )
    {
        
        let sortId = 0
        
        if ( !isNaN( parseInt( field ) )
             && '' + parseInt( field ) === '' + field ) {
            return parseInt( field )
        }
        
        if ( undefined === field ) {
            return sortId
        }
        
        sortId = '' + field
        return sortId
        
    }
    
    multiSortObjects( sortable, sortby, ignorePinning, returnResult )
    {
        
        if ( undefined === sortable ) {
            return
        }
        
        if ( undefined === sortby ) {
            return this.sortObjects( sortable, 'timestamp', 'ascending' )
        }
        
        let pinningSorted = false
        
        for ( let i = 0; i < sortby.length; i++ ) {
            
            let s         = sortby[ i ],
                sort      = s[ 0 ],
                direction = s[ 1 ]
            
            if ( sort === 'pinned' ) {
                pinningSorted = true
            }
            
            for ( let n in sortable ) {
                
                if ( undefined !== sortable[ n ] ) {
                    
                    let sortId = this.sortId( sortable[ n ][ sort ] )
                    
                    if ( undefined === sortable[ n ].pinned ) {
                        sortable[ n ].pinned = false
                    }
                    if ( undefined === sortable[ n ][ sort ]
                         || ( 'timestamp' === sort && undefined !== sortable[ n ][ 'tsmpEnd' ] ) ) {
                        if ( 'update' === sort
                             && undefined !== sortable[ n ][ 'timestamp' ] ) {
                            sortId = sortable[ n ][ 'timestamp' ]
                        } else {
                            if ( 'timestamp' === sort
                                 && undefined !== sortable[ n ][ 'tsmpEnd' ] ) {
                                sortId = sortable[ n ][ 'tsmpEnd' ]
                            } else {
                                if ( 'update' === sort
                                     && undefined !== sortable[ n ][ 'lists' ] ) {
                                    
                                    let minUpdate = undefined !== sortable[ n ][ 'tsmpEnd' ] ? sortable[ n ][ 'tsmpEnd' ] : -1
                                    for ( let l in sortable[ n ][ 'lists' ] ) {
                                        let list = sortable[ n ][ 'lists' ][ l ]
                                        if ( list.update > minUpdate ) {
                                            minUpdate = list.update
                                        }
                                    }
                                    sortId = minUpdate
                                } else {
                                    sortId = '0'
                                }
                            }
                        }
                        
                    }
                    
                    if ( sort == 'sortableTimestamp' ) {
                        sortId = sortId.replace( '.', '' )
                        if ( sortId.length < 6 ) {
                            sortId = sortId.substring( 0, 4 ) + '0' + sortId.substring( 4, 1 )
                        }
                        sortId = parseInt( sortId )
                    }
                    
                    if ( sort == 'color' ) {
                        sortId = this.getColorSortId( sortable[ n ][ sort ] )
                    }
                    
                    if ( sort == 'done' ) {
                        sortId = sortable[ n ][ sort ] === true || sortable[ n ][ sort ] === 1 ? 'A' : 'Z'
                    }
                    
                    sortable[ n ].__sortId = sortId
                    
                }
                
            }
            
            if ( 'ascending' == direction
                 || undefined === direction ) {
                sortable.sort( _sortDefaultAsc )
            } else {
                sortable.sort( _sortDefaultDesc )
            }
            
            if ( !pinningSorted && true !== ignorePinning ) {
                this.sortObjects( sortable, 'pinned', 'descending' )
            }
            
        }
        
        if ( true === returnResult ) {
            return sortable
        }
        
    }
    
    sortListByType( list, type )
    {
        
        type = type || list[ 0 ].type
        let sortingDirections = this.settings.getSetting( 'sortingDirections' )[ type ]
        
        if ( undefined === sortingDirections || undefined === type ) {
            return list
        }
        
        return this.multiSortObjects( list, sortingDirections )
        
    }
    
}