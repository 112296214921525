<template>
    <div class="studentaccess">
        
        <Button :type="'hide '+( this.active ? '' : 'active' )"
                :title="$core.t( 'button-show-credentials' )"
                @clicked="active = !active"/>
        
        <div class="clearfix"></div>
        <div class="inline">
            <div class="list-row">
                <div class="form-caption"><strong>Login</strong></div>
                <div class="form-component text"><strong>{{ item.username }}</strong></div>
                <div class="clearfix"></div>
            </div>
            <div v-if="active" class="list-row">
                <div class="form-caption">initiales <strong>Passwort</strong></div>
                <div class="form-component text">{{ item.passphrase }}</div>
                <div class="clearfix"></div>
            </div>
            <div v-if="active" class="list-row">
                <div class="form-caption">Anmeldungs-<strong>QR-Code</strong></div>
                <div class="form-component text"><StudentAccessQr :localAccess="item"/></div>
                <div class="clearfix"></div>
            </div>
            <div v-if="active" class="list-row">
                <div class="form-caption"><strong>PUK</strong> zur Wiederherstellung eines vergessenen Passworts</div>
                <div class="form-component text">{{ item.keys[ 0 ].backupKey }}</div>
                <div class="clearfix"></div>
            </div>
            <div class="list-row">
                <div class="form-caption"><strong>gültig ab</strong></div>
                <div class="form-component text">{{
                        $core.getReformatter().dateFromTimestamp( item.timestamp )
                    }}</div>
                <div class="clearfix"></div>
            </div>
            <div class="list-row">
                <div class="form-caption"><strong>Login-URL</strong></div>
                <div class="form-component text"><strong>https://app.entzettelt.de</strong></div>
                <div class="clearfix"></div>
            </div>
        </div>
        
        <pre>
            {{ item }}
        </pre>
    </div>
</template>

<script>
/*eslint-disable*/
import StudentAccessQr from "@/components/routes/secretary/studentAccess/StudentAccessQr.vue";
import Button from "@/components/elements/defaults/Button.vue";

export default {
    
    name:       'StudentAccessBase',
    components: { Button, StudentAccessQr },
    props:      {
        item: { type: Object, required: true }
    },
    
    data()
    {
        return {
            docHidden: true,
            active:    false,
            viewKey:   this.$core.getUuid().generate()
        }
    },
    
    created()
    {
    },
    
    methods: {
        
        download()
        {
            let a = document.createElement( 'a' )
            a.download = 'entzettelt-Zugang für '
                         + this.$props.studentReference.firstname + ' '
                         + this.$props.studentReference.lastname + ' - '
                         + 'vom ' + this.$core.getReformatter().dateFromTimestamp( this.$props.localAccess.timestamp ) + '.pdf'
            a.href = this.$props.localAccess.accessDocument
            this.$nextTick()
                .then( () =>
                {
                    a.click()
                } )
        },
        
        regenerateDocument()
        {
            this.$core.getUi().showBlocker( 'Augenblick bitte...', 'Ein neues Einrichtungsdokument wird erzeugt' )
            this.active = false
            this.$nextTick()
                .then( () =>
                {
                    
                    this.$core.getRegisterStudentAccessHelper()
                        .regeneratePdf( this.$props.studentReference, this.$props.localAccess )
                        .then( blob =>
                        {
                            
                            let accessClass = this.$core.baseClass( 'studentAccess' ),
                                accessClone = JSON.parse( JSON.stringify( this.$props.localAccess ) )
                            
                            accessClone.accessDocument = blob
                            
                            accessClass.update(
                                accessClone,
                                accessClone.localId,
                                accessClone.remoteId,
                                accessClone.timestamp,
                                accessClone.localKey )
                            
                            this.$core.getEventManager()
                                .append( 'storable-after-updated-' + accessClone.localId, () =>
                                {
                                    
                                    this.$core.getUi()
                                        .delay( () =>
                                        {
                                            this.active = true
                                            this.viewKey = this.$core.getUuid().generate()
                                            this.$core.getUi().hideBlocker()
                                        }, 500 )
                                    
                                } )
                            
                        } )
                    
                } )
            
        }
    }
    
}
</script>