<template>
    <transition appear :name="$core.settings().getTransition( 'fastzoom' )">
        <div :class="'button round '+type+' '+addClass" @touchstart="handleClick" @click="handleClick"
             :title="title || $core.getTranslation().translate( 'button-title-'+type )">
        </div>
    </transition>
</template>

<script>
export default {
    name:    'Button',
    props:   {
        type:     { Type: String, required: true },
        addClass: { Type: String, required: false },
        title:    { Type: String, required: false }
    },
    emits:   [ 'clicked' ],
    methods: {
        handleClick( event )
        {
            this.$core.f().skip( event )
            this.$emit( 'clicked' )
        }
    }
}
</script>