/* eslint-disable */
export default class Login
{

    constructor( core )
    {

        //window.forceOffline = true

        if( !Login.instance )
        {

            this.database = core.getDatabase()
            this.client = core.getClient()
            this.store = core.getStore()
            this.router = core.getRouter()
            this.logger = core.getLogger()

            this.setState = ( key, value ) =>
            {
                core.setState( key, value )
            }

            this.ui = core.getUi()
            this.crypto = core.getCryptoCore()
            this.eventManager = core.getEventManager()
            this.syncWorker = core.getSyncWorker()
            this.uuid = core.getUuid()
            this.s = core.s()
            this.config = core.getConfig()
            this.settings = core.settings()

            this.t = ( key, params ) =>
            {
                return core.t( key, params )
            }

            this.reauthorizing = false
            this.lastLoginMaxAge = 86400 * 1000 * 7
            this.logoutForceTimer = null
            this.cleanupMessageTimer = null

            this.eventManager.addOnce( 'on-session-timeout', () =>
            {
                this.logout( true )
            } )

            setTimeout( () =>
            {
                this.eventManager.dispatchAndRemove( 'on-auth-ready' )
            }, 500 )

            Login.instance = this

        }

        return Login.instance

    }

    destruct()
    {
        delete Login.instance
    }

    answerPrivateKeyChallenge( challengeResult )
    {

        this.ui.showBlocker( this.t( 'login-blocker-title' ), this.t( 'login-blocker-text-challenge-1' ) )

        let message = {
            method         : 'users.tryImportPrivateKeys',
            looseSession   : true,
            challengeResult: challengeResult
        }

        return new Promise( ( result, reject ) =>
        {

            this.client.request( message )
                .then( socketResponse =>
                {

                    let response = socketResponse.result
                    this.ui.showBlocker( this.t( 'login-blocker-title' ), this.t( 'login-blocker-text-challenge-2' ) )

                    return result( {
                        uuid      : response[ 0 ].uuid,
                        privateKey: response[ 0 ].private_key
                    } )

                } )
                .catch( error =>
                {

                    this.ui.showBlocker( this.t( 'login-blocker-error' ), this.t( 'login-blocker-text-challenge-error' ) )
                    return reject( error )

                } )

        } )
    }

    importPrivateKey( username, password, retry )
    {
        return new Promise( ( resolve, reject ) =>
        {

            this.ui.showBlocker( this.t( 'login-blocker-title' ), this.t( 'login-blocker-text-keyimport' ) )
            if( this.store.getters.online !== true
                || this.reauthorizing === true )
            {
                let privateKey = this.store.getters.encryptedPrivateKey
                if( false !== privateKey )
                {
                    return resolve( { uuid: this.store.getters.uuid, privateKey: privateKey } )
                }
                else
                {
                    return reject( 'ERROR_PKEY_READ' )
                }
            }

            let message = {
                method      : 'users.tryPrivateKeyBackup',
                looseSession: true,
                username    : username
            }

            this.client.request( message )
                .then( response =>
                {

                    this.ui.showBlocker( this.t( 'login-blocker-title' ), this.t( 'login-blocker-text-keyimport' ) )
                    let challengeResult = this.crypto.decrypt( response.result, password )

                    this.answerPrivateKeyChallenge( challengeResult )
                        .then( result =>
                        {

                            return resolve( result )

                        } )
                        .catch( error =>
                        {

                            this.logger.error( 'no challenge response received: ' + error )
                            this.ui.showBlocker( this.t( 'login-blocker-error' ), this.t( 'login-blocker-text-keyimport-error' ) )
                            return reject( error )

                        } )

                } )
                .catch( error =>
                {
                    if( 'ERR_NOT_CONNECTED' === error
                        && undefined === retry )
                    {
                        this.logger.log( 'received error "' + error + '", but online state was "connected" before - resetting websocket client and starting over...' )
                        this.eventManager
                            .add( 'after-websocket-reset', () =>
                            {
                                return resolve( this.importPrivateKey( username, password, true ) )
                            } )
                        this.client.reset()
                    }
                    else
                    {
                        this.logger.error( 'no challenge response received: ' + error )
                        this.ui.showBlocker( this.t( 'login-blocker-error' ), this.t( 'login-blocker-text-keyimport-error-catch', [ error ] ) )
                        return reject( error )
                    }

                } )

        } )

    }

    getPrivateKey( username, password )
    {

        return new Promise( ( resolve, reject ) =>
        {

            this.importPrivateKey( username, password )
                .then( result =>
                {
                    return resolve( result )
                } )
                .catch( () =>
                {
                    return reject( 'ERROR_IMPORT_PRIVATE_KEY' )
                } )

        } )

    }

    getUuid( username, uuid )
    {

        this.ui.showBlocker( this.t( 'login-blocker-title' ), this.t( 'login-blocker-text-uuid' ) )

        let message = {
            method      : 'users.importUUID',
            looseSession: true,
            username    : username,
            deviceUuid  : uuid
        }

        return new Promise( ( resolve, reject ) =>
        {

            this.client.request( message )
                .then( socketResponse =>
                {

                    return resolve( socketResponse.result )

                } )
                .catch( () =>
                {

                    this.logger.error( 'failed to import device UUID' )
                    return reject( 'ERROR_IMPORT' )

                } )

        } )

    }

    /*eslint-disable*/
    execChallenge( username, password, privateKey )
    {

        return new Promise( ( resolve, reject ) =>
        {

            this.ui.showBlocker( this.t( 'login-blocker-title' ), this.t( 'login-blocker-text-unlocking-key' ) )

            let message = {
                method      : 'users.tryLogin',
                looseSession: true,
                username    : username,
                deviceUuid  : this.store.getters.uuid
            }

            this.client.request( message )
                .then( socketResponse =>
                {

                    if( 'BRANCH_NOT_ALLOWED' === socketResponse.result )
                    {

                        this.logger.error( 'branch error: ' + socketResponse.result )
                        this.ui.showBlocker( this.t( 'login-blocker-error' ), this.t( 'login-blocker-text-branch-mismatch' ) )
                        return reject( 'ERROR_BRANCH' )

                    }
                    else
                    {

                        let decryptedPrivateKey = this.crypto._decryptPrivateKey( privateKey.privateKey, password )
                        let decryptedToken = this.crypto.decryptWithPrivateKey( decryptedPrivateKey, socketResponse.result )

                        return resolve( {
                            lastBranch: socketResponse.lastBranch,
                            token     : decryptedToken,
                            privateKey: decryptedPrivateKey
                        } )
                    }

                } )
                .catch( error =>
                {

                    this.logger.error( 'Unlock error: ' + error )
                    this.ui.showBlocker( this.t( 'login-blocker-error' ), this.t( 'login-blocker-text-error-unlock' ) )
                    return reject( 'ERROR_UNWRAP' )

                } )

        } )

    }

    verifyToken( username, token )
    {

        let message = {
            method      : 'users.verifyLoginToken',
            looseSession: true,
            username    : username,
            branch      : this.config.branch,
            deviceUuid  : this.store.getters.uuid,
            token       : token
        }

        this.ui.showBlocker( this.t( 'login-blocker-title' ), this.t( 'login-blocker-text-token-validation' ) )

        return new Promise( ( resolve, reject ) =>
        {

            this.client.request( message )
                .then( socketResponse =>
                {

                    if( undefined !== socketResponse.message )
                    {

                        this.ui.showBlocker( this.t( 'login-blocker-error' ), this.t( 'login-blocker-text-branch-mismatch' ) )
                        return reject( 'ERROR_BRANCH' )

                    }
                    else
                    {
                        this.ui.showBlocker( this.t( 'login-blocker-title' ), this.t( 'login-blocker-text-token-valid' ) )
                        return resolve( socketResponse.result )
                    }

                } )
                .catch( error =>
                {

                    if( Array.isArray( error ) )
                    {
                        let code           = error[ 0 ],
                            additionalData = error[ 1 ]

                        this.ui.showBlocker( this.t( 'login-blocker-error' ), this.t( 'login-blocker-text-branch-mismatch' ) )
                        return reject( [ code, additionalData ] )

                    }
                    else
                    {
                        this.ui.showBlocker( this.t( 'login-blocker-title' ), this.t( 'login-blocker-text-token-invalid' ) )
                        return reject( undefined !== error ? error : 'ERROR_TOKEN' )
                    }
                } )

        } )

    }

    startApp( tokenResult, result, password )
    {

        return new Promise( resolve =>
        {

            this.ui.showBlocker( this.t( 'login-blocker-title-success' ), this.t( 'login-blocker-body-success' ) )

            let cryptedId = this.crypto.encrypt( '' + tokenResult.idUser, password )
            window.authKey = tokenResult.idSession

            this.store.commit( 'setPrivateKey', btoa( result.privateKey ) )
            this.store.commit( 'setEncryptedPrivateKey', tokenResult.privateKey )
            this.store.commit( 'setPublicKey', tokenResult.publicKey )
            this.store.commit( 'setSecondFactor', tokenResult.secondFactor === null ? 'inactive' : tokenResult.secondFactor )
            this.store.commit( 'setIdSession', tokenResult.idSession )
            this.store.commit( 'setUsername', tokenResult.username )
            this.store.commit( 'setIdUser', tokenResult.idUser )
            this.store.commit( 'setLastIdUser', cryptedId )
            this.store.commit( 'setAuthorized', true )
            this.store.commit( 'setLastSyncRun', 0 )
            this.setState( 'justLoggedIn', true )

            setTimeout( () =>
            {

                this.eventManager.dispatch( 'on-login-state-change' )
                if( true !== this.store.getters.offlineAuthorized )
                {
                    this.eventManager.add( 'login-unwait', () =>
                    {

                        return resolve()

                    } )
                }
                else
                {
                    return resolve()
                }

            }, 500 )

        } )

    }

    performExecChallenge( username, password, privateKey )
    {
        return new Promise( ( resolve, reject ) =>
        {

            this.execChallenge( username, password, privateKey )
                .then( result =>
                {

                    let lastBranch = result.lastBranch
                    this.ui.showBlocker( this.t( 'login-blocker-title' ), this.t( 'login-blocker-text-token-validation' ) )

                    this.verifyToken( username, result.token )
                        .then( tokenResult =>
                        {

                            this.store.commit( 'setIsStudent', tokenResult.isStudent )
                            this.setState( 'shadowCopiesAllowed', 1 === tokenResult.isStudent )

                            let promises = []

                            promises.push( new Promise( resolve =>
                            {
                                this.logger.log( 'storage preparation after challenge and token verification...' )
                                return resolve()
                            } ) )

                            Promise.all( promises )
                                   .then( () =>
                                   {

                                       if( null !== tokenResult.secondFactor
                                           && true !== this.store.getters.offlineAuthorized )
                                       {
                                           this.ui.requestSecondFactor( tokenResult, result )
                                               .then( () =>
                                               {

                                                   this.startApp( tokenResult, result, password )
                                                       .then( () =>
                                                       {
                                                           return resolve()
                                                       } )

                                               } )
                                               .catch( () =>
                                               {

                                                   this.ui.hideBlocker( true )
                                                   return reject( 'ERROR_SECOND_FACTOR_VALIDATE' )

                                               } )
                                       }
                                       else
                                       {

                                           this.startApp( tokenResult, result, password )
                                               .then( () =>
                                               {
                                                   return resolve()
                                               } )

                                       }

                                   } )

                        } )
                        .catch( error =>
                        {
                            return reject( error )
                        } )

                } )
                .catch( error =>
                {
                    switch( error )
                    {
                        case 'ERROR_BRANCH':
                            return reject( 'ERROR_BRANCH' )
                        default:
                            return reject( 'ERROR_CHALLENGE' )
                    }
                } )

        } )

    }

    onlineChallenge( username, password )
    {

        return new Promise( ( resolve, reject ) =>
        {
            this.getPrivateKey( username, password )
                .then( privateKey =>
                {
                    this.getUuid( username, this.store.getters.uuid )
                        .then( deviceUuid =>
                        {
                            this.store.commit( 'setUuid', deviceUuid )
                            return resolve( this.performExecChallenge( username, password, privateKey ) )
                        } )
                        .catch( () =>
                        {
                            return reject( 'ERROR_UUID' )
                        } )

                } )
                .catch( error =>
                {
                    return reject( error )
                } )
        } )

    }

    tryCrypt( privateKey )
    {

        let testString = this.crypto.generateRandomString( 512 ),
            testEnc    = this.crypto.encryptWithPublicKey( this.store.getters.publicKey, testString )

        if( false !== testEnc )
        {

            let testDecrypt = this.crypto.decryptWithPrivateKey( privateKey, testEnc )
            return testDecrypt === testString

        }

        return false

    }

    setupOfflineSession( username, password, decryptedPrivateKey )
    {

        return new Promise( resolve =>
        {

            let passphrase = this.crypto.encryptWithPublicKey( this.store.getters.publicKey, password )

            let lastIdUser = this.crypto.decrypt( this.store.getters.lastIdUser, password )
            if( false !== lastIdUser
                && undefined !== lastIdUser
                && null !== lastIdUser
                && 0 < parseInt( lastIdUser ) )
            {
                this.store.commit( 'setIdUser', lastIdUser )
            }

            this.store.commit( 'setPrivateKey', btoa( decryptedPrivateKey ) )
            this.store.commit( 'setIdSession', 'offline-' + this.uuid.generate() )
            this.store.commit( 'setUsername', username )
            this.store.commit( 'setAuthorized', true )
            this.store.commit( 'setOfflineAuthorized', true )
            this.store.commit( 'setShouldSync', true )
            this.store.commit( 'setLastSyncRun', 0 )
            this.store.commit( 'setLastTick', Date.now() )
            this.store.commit( 'setPassphrase', passphrase )

            return resolve()

        } )

    }

    offlineChallenge( username, password )
    {

        this.ui.blockerText( this.t( 'login-blocker-text-offline-validation' ) )
        return new Promise( ( resolve, reject ) =>
        {

            this.getPrivateKey( username, password )
                .then( privateKey =>
                {

                    let decryptedPrivateKey = this.crypto._decryptPrivateKey( privateKey.privateKey, password )
                    if( false !== decryptedPrivateKey )
                    {

                        if( this.tryCrypt( decryptedPrivateKey ) )
                        {

                            if( null === this.store.getters.secondFactor
                                || null === this.store.getters.offlineFailures )
                            {
                                return reject( 'ERROR_SECOND_FACTOR_OFFLINE' )
                            }

                            if( 'inactive' !== this.store.getters.secondFactor )
                            {

                                this.ui.requestSecondFactor( undefined, { privateKey: decryptedPrivateKey } )
                                    .then( () =>
                                    {

                                        this.setupOfflineSession( username, password, decryptedPrivateKey )
                                            .then( () =>
                                            {
                                                return resolve()
                                            } )

                                    } )
                                    .catch( () =>
                                    {

                                        this.ui.hideBlocker( true )
                                        return reject( 'ERROR_SECOND_FACTOR_VALIDATE' )

                                    } )
                            }
                            else
                            {

                                this.setupOfflineSession( username, password, decryptedPrivateKey )
                                    .then( () =>
                                    {
                                        return resolve()
                                    } )

                            }

                        }
                        else
                        {

                            let count = parseInt( this.store.getters.offlineFailures ) + 1
                            if( 3 > count )
                            {
                                this.store.commit( 'setOfflineFailures', count )
                                return reject( 'ERROR_PKEY_CRYPT' )
                            }
                            else
                            {

                                this.resetAllStorage()
                                    .then( () =>
                                    {

                                        setTimeout( () =>
                                        {

                                            return reject( 'ERROR_PKEY_CRYPT_TOO_MANY_TRIES' )

                                        }, 1000 )

                                    } )

                            }

                        }

                    }
                    else
                    {
                        return reject( 'ERROR_PKEY_UNLOCK' )
                    }

                } )
                .catch( () =>
                {
                    return reject( 'ERROR_PKEY_READ' )
                } )

        } )
    }

    resetAllStorage()
    {
        return new Promise( resolve =>
        {
            this.database.truncateAll( true )
                .then( () =>
                {

                    this.store.dispatch( 'prepareUserChange' )
                        .then( () =>
                        {

                            this.eventManager.dispatch( 'crypto-reset' )
                            this.eventManager.dispatch( 'full-objects-cache-flush' )
                            this.s.flush()
                            this.settings.flush()

                            setTimeout( () =>
                            {

                                return resolve()

                            }, 1000 )

                        } )

                } )
        } )
    }

    userChange( username )
    {

        return new Promise( ( resolve, reject ) =>
        {

            let oldUser = ( '' + this.store.getters.username ).trim().toLowerCase()
            let newUser = username.trim().toLowerCase()

            if( newUser === oldUser )
            {
                return resolve()
            }
            else
            {
                this.database.readAllObjects( 'uploads' )
                    .then( list =>
                    {
                        if( 0 < list.length
                            && 'testnutzer' !== oldUser )
                        {
                            this.ui.blockerText( this.t( 'login-message-unsynced-entries' ) )
                            setTimeout( () =>
                            {

                                this.ui.unlockBlocker()
                                this.ui.hideBlocker()
                                return reject( 'UNSYNCED' )

                            }, 5000 )
                        }
                        else
                        {
                            if( this.store.getters.online === true )
                            {
                                this.ui.blockerText( this.t( 'login-message-database-preparation' ) )
                                this.resetAllStorage()
                                    .then( () =>
                                    {

                                        this.eventManager.dispatch( 'on-user-change' )
                                        return resolve()

                                    } )

                            }
                            else
                            {
                                this.ui.blockerText( this.t( 'login-message-offline-userchange' ) )
                                setTimeout( () =>
                                {

                                    this.ui.unlockBlocker()
                                    this.ui.hideBlocker()
                                    return reject()

                                }, 5000 )

                            }
                        }
                    } )

            }

        } )

    }

    startAppOffline( result )
    {

        return new Promise( resolve =>
        {

            this.ui.showBlocker( this.t( 'login-blocker-title-success' ), this.t( 'login-blocker-body-success' ) )
            this.s.count( 'count_login_offline' )
            setTimeout( () =>
            {

                this.ui.unlockBlocker()
                this.setState( 'justLoggedIn', false )
                this.eventManager.dispatch( 'on-login-state-change' )
                window.authKey = this.store.getters.idSession

                return resolve( result )

            }, 2000 )

        } )
    }

    lastLoginExceeded()
    {
        return new Promise( resolve =>
        {

            let now       = Date.now(),
                lastLogin = this.store.getters.lastLogin,
                minTsmp   = new Date( now - this.lastLoginMaxAge ).getTime(),
                isValid   = lastLogin !== undefined
                            && lastLogin !== null
                            && lastLogin > minTsmp

            if( !isValid )
            {
                this.ui.blockerText( this.t( 'login-blocker-database-reset' ) )
                this.resetAllStorage()
                    .then( () =>
                    {
                        return resolve()
                    } )
            }
            else
            {
                return resolve()
            }

        } )
    }

    login( username, password )
    {

        return new Promise( ( resolve, reject ) =>
        {

            this.userChange( username )
                .then( () =>
                {

                    this.lastLoginExceeded()
                        .then( () =>
                        {

                            if( this.store.getters.online === true )
                            {

                                this.onlineChallenge( username, password )
                                    .then( result =>
                                    {

                                        this.s.count( 'count_login_online' )
                                        this.store.commit( 'setAutoRouteDisabled', null )
                                        this.store.commit( 'setLastLogin', Date.now() )
                                        return resolve( result )

                                    } )
                                    .catch( error =>
                                    {
                                        this.s.count( 'count_login_online_failure' )
                                        return reject( error )
                                    } )

                            }
                            else
                            {

                                this.offlineChallenge( username, password )
                                    .then( result =>
                                    {

                                        this.startAppOffline( result )
                                            .then( () =>
                                            {
                                                this.store.commit( 'setLastLogin', Date.now() )
                                                this.store.commit( 'setAutoRouteDisabled', null )
                                                return resolve( result )
                                            } )

                                    } )
                                    .catch( error =>
                                    {
                                        this.s.count( 'count_login_offline_failure' )
                                        return reject( error )
                                    } )

                            }

                        } )
                        .catch( () =>
                        {
                            return reject()
                        } )

                } )
                .catch( e =>
                {
                    return reject( e )
                } )

        } )

    }

    reauthorizeOfflineSession()
    {

        this.logger.clog( 'Core:Authentication:Login:reauthorizeOfflineSession', 'start reauthorization' )
        return new Promise( ( resolve, reject ) =>
        {

            if( this.reauthorizing === true )
            {
                return resolve( 'WAIT' )
            }
            else
            {
                this.reauthorizing = true
                let username = this.store.getters.username
                let key = atob( this.store.getters.privateKey )
                let privateKey = new Uint8Array( key.split( ',' ) )

                let password = this.crypto.decryptWithPrivateKey(
                    privateKey,
                    this.store.getters.passphrase
                )

                this.onlineChallenge( username, password )
                    .then( () =>
                    {

                        this.reauthorizing = false
                        this.store.commit( 'setOfflineAuthorized', false )
                        this.store.commit( 'setPassphrase', false )
                        this.ui.hideBlocker()
                        return resolve()

                    } )
                    .catch( () =>
                    {
                        this.reauthorizing = false
                        return reject()
                    } )
            }

        } )

    }

    _performLogout( blockerHash )
    {

        clearTimeout( this.cleanupMessageTimer )
        this.setState( 'performing-logout', true )

        this.eventManager.dispatch( 'mx-flush-and-forget' )
        window.authKey = null
        delete window.authKey

        this.store.commit( 'setAutoRouteDisabled', true )

        this.ui.delay( () =>
        {

            this.store.dispatch( 'destroySession' )
                .then( () =>
                {
                    this.ui.hideBlocker( blockerHash )
                    this.setState( 'performing-logout', false )
                    this.router.push( { name: 'login' } )
                } )

        }, 2000 )

    }

    logout( sessionTimeout )
    {

        let hash = this.ui.reserveBlocker( true )

        if( true === sessionTimeout )
        {
            this.store.commit( 'setLogoutMessage', {
                title  : this.t( 'logout-title-session-timeout' ),
                message: this.t( 'logout-body-session-timeout' ),
                type   : 'warn',
                active : true
            } )
            this.ui.showBlocker( this.t( 'logout-blocker-title' ), this.t( 'logout-blocker-text-session-timeout' ), undefined, true, hash )
        }
        else
        {
            this.ui.showBlocker( this.t( 'logout-blocker-title' ), this.t( 'logout-blocker-text-goodbye' ), undefined, true, hash )
            this.s.count( 'count_logout' )
        }

        this.cleanupMessageTimer = setTimeout( () =>
        {

            this.ui.blockerText( this.t( 'logout-blocker-text-cleanup' ), hash )

        }, 5000 )

        this.logoutForceTimer = setTimeout( () =>
        {

            this.syncWorker.reset( true )
            this._performLogout(hash)

        }, 10000 )

        this.syncWorker.awaitAllSynced()
            .then( () =>
            {

                clearTimeout( this.logoutForceTimer )
                this._performLogout( hash )

            } )

    }

}